import { RefObject, useEffect, useState } from 'react'
import styles from './styles.module.scss'

interface InputCharCounterProps {
    inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>
    value: string
}

const InputCharCounter: React.FC<InputCharCounterProps> = ({ inputRef, value }) => {
    const [count, setCount] = useState(0)
    const [isReady, setReady] = useState(false)

    useEffect(() => {
        if (!inputRef.current || isReady) {
            return
        }

        const input = inputRef.current

        setReady(true)
        setCount(input.maxLength - (input.value || '').toString().length)

        input.addEventListener('input', onInput, { passive: true })

        return () => {
            input.removeEventListener('input', onInput)
        }
    }, [inputRef])

    useEffect(() => {
        if (!inputRef.current) {
            return
        }

        const input = inputRef.current

        setCount(input.maxLength - (input.value || '').toString().length)
    }, [value])

    const onInput = (event: Event) => {
        const input = event.target as HTMLTextAreaElement | HTMLInputElement

        if (input.maxLength) {
            setCount(input.maxLength - input.value.length)
        }
    }

    return (
        <div className={styles.wrapper}>
            {count}
        </div>
    )
}

export default InputCharCounter
