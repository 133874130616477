import classnames from 'classnames'
import { KalkulInc } from '../../types/Kalkul'
import styles from './styles.module.scss'

export interface ButtonProps {
    isBusy?: boolean
    isDisabled?: boolean
    onClick?: () => void
    className?: string
    size?: KalkulInc.UI.Size
    cta?: boolean
    action?: boolean
    theme?: 'theme-danger'
    pill?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, isBusy, isDisabled, onClick, className, size, cta, action, theme, pill}) => {
    let classNames = {
        [styles.wrapper]: true,
    }

    if (className) {
        classNames = {
            ...classNames,
            [className]: true,
        }
    }

    return (
        <div
            className={classnames(classNames)}
            data-busy={isBusy}
            data-disabled={isDisabled}
            data-size={size || 'm'}
            data-cta={cta}
            data-action={action}
            data-theme={theme}
            data-pill={pill}
            { ...(onClick ? { onClick } : null) }
        >
            <div className={styles.content} data-content>
                {children}
            </div>
            <div className={styles.loader}></div>
        </div>
    )
}
export default Button
