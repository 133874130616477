import { GetServerSideProps, NextPage } from 'next'
import { ClientSafeProvider, getCsrfToken, getProviders, getSession, signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { KeyboardEvent, Reducer, useReducer, useRef } from 'react'
import Input from '@kalkul/components/components/input-text'
import styles from './styles.module.scss'
import { requestNewPassword } from '../../services/api'
import toast from 'react-hot-toast'
import Button from '@kalkul/components/components/button'
import { getKeys } from '@kalkul/components/services/helpers'
import classnames from 'classnames'
import CopyrightNote from '../../components/copyright-note'
import { sentryUserClear } from '../../services/sentry'
import ExternalLink from '@kalkul/components/components/external-link'

const STUCK_TIMEOUT = 5000

interface SignInProps {
    providers: {
        [key: string]: ClientSafeProvider
    }
}

type ErrorString = 'CredentialsSignin' | 'AccessDenied'

interface SignInState {
    providerUsed: string
    isReloading: boolean
    isPasswordSent: boolean
    isReloadVisible: boolean
    credentials: {
        email: string
        password: string
    }
}

const SignIn: NextPage<SignInProps> = ({ providers }) => {
    const error = useRouter().query.error as ErrorString
    const { t } = useTranslation('auth')
    const router = useRouter()
    const refCredentialsForm = useRef<HTMLFormElement>(null)

    const [state, setState] = useReducer<Reducer<SignInState, Partial<SignInState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            providerUsed: '',
            isReloading: false,
            isPasswordSent: false,
            isReloadVisible: false,
            credentials: {
                email: '',
                password: '',
            },
        }
    )

    const getErrorString = (error: ErrorString) => {
        switch (error) {
            case 'AccessDenied':
            case 'CredentialsSignin': {
                return t('error.accessDenied')
            }

            default:
                return ''
        }
    }

    const onSignIn = (providerId: string) => () => {
        setState({
            providerUsed: providerId,
        })

        if (providerId === 'aura') {
            requestAnimationFrame(() => {
                const inputsCollection: HTMLFormControlsCollection | undefined = refCredentialsForm.current?.elements

                if (!inputsCollection) {
                    return
                }

                const inputs = Array.from(inputsCollection) as HTMLInputElement[]
                const credentials = {
                    email: inputs.find(input => input.name === 'email')?.value.trim(),
                    password: inputs.find(input => input.name === 'password')?.value.trim(),
                }

                signIn('aura', credentials)
            })
        } else {
            signIn(providerId)
        }

        setTimeout(() => {
            setState({
                isReloadVisible: true,
            })
        }, STUCK_TIMEOUT)
    }

    const onReload = () => {
        setState({
            isReloading: true,
        })

        requestAnimationFrame(router.reload)
    }

    const onPasswordRequest = async () => {
        setState({
            isPasswordSent: true,
        })

        await requestNewPassword(state.credentials.email)

        toast.success(`${t('passwordSent')} ${state.credentials.email}`)
    }

    const onCredentialsFormChange = () => {
        const inputsCollection: HTMLFormControlsCollection | undefined = refCredentialsForm.current?.elements

        if (!inputsCollection) {
            return
        }

        const credentials = {
            email: '',
            password: '',
        }
        const inputs = Array.from(inputsCollection) as HTMLInputElement[]

        inputs.forEach(input => {
            if (input.name === 'email' || input.name === 'password') {
                credentials[input.name] = input.value.trim()
            }
        })

        setState({
            credentials,
        })
    }

    const onKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key.toLowerCase() === 'enter') {
            onSignIn('aura')()
        }
    }

    const canRestorePassword = state.credentials.email && !state.isPasswordSent
    const canSubmitCredentialsForm = getKeys(state.credentials).every(key => state.credentials[key])
    const aura = Object.values(providers).find(provider => provider.id === 'aura')
    const oAuthProviders = Object.values(providers).filter(provider => provider.id !== 'aura')

    return (
        <div className={styles.wrapper}>
            <div className={styles.controls}>
                {
                    error &&
                        <div className={styles.error}>
                            {getErrorString(error)}
                        </div>
                }
                {
                    aura &&
                        <>
                            <form
                                method="post"
                                action={`/api/auth/callback/${aura.id}`}
                                ref={refCredentialsForm}
                                onChange={onCredentialsFormChange}
                                onKeyDown={onKeyDown}
                            >
                                <h1 className={styles.header}><span><b><i>{t('header.0')}</i></b>-{t('header.1')}</span><sup>{t('header.2')}</sup></h1>
                                <div className={styles.intro}>{t('intro.0')}<br /><ExternalLink href="https://motoko141066.typeform.com/to/rDOeCRMS">{t('intro.1')}</ExternalLink></div>
                                <div className={styles.row}>
                                    <Input name="email" type="text" placeholder="username@domain" readOnly={!!state.providerUsed} autoFocus autoComplete="email" />
                                </div>
                                <div className={styles.row}>
                                    <Input name="password" type="password" placeholder="password" readOnly={!!state.providerUsed} autoComplete="current-password" />
                                </div>
                                <div className={classnames(styles.textCTA, styles.forgotPassword)} data-disabled={!canRestorePassword || !!state.providerUsed} onClick={onPasswordRequest}>
                                    {t('passwordRestore')}
                                </div>
                            </form>
                            <div className={styles.button}>
                                <Button
                                    onClick={onSignIn('aura')}
                                    isDisabled={!canSubmitCredentialsForm || (state.providerUsed !== 'aura' && !!state.providerUsed)}
                                    isBusy={state.providerUsed === 'aura'}
                                >
                                    {t('signInWith')} {t('email')}
                                </Button>
                                {
                                    (state.isReloadVisible && state.providerUsed === 'aura') &&
                                        <div className={styles.textCTA} data-disabled={state.isReloading} onClick={onReload}>
                                            {t('reload')}
                                        </div>
                                }
                            </div>
                        </>
                }
                {
                    oAuthProviders.length > 0 &&
                        <div className={styles.or}>{t('or')}</div>
                }
                {
                    oAuthProviders.map(provider => (
                        <div key={provider.name} className={styles.button}>
                            <Button
                                onClick={onSignIn(provider.id)}
                                isBusy={state.providerUsed === provider.id}
                                isDisabled={state.providerUsed !== provider.id && !!state.providerUsed}
                            >
                                {t('signInWith')} {provider.name}
                            </Button>
                            {
                                (state.isReloadVisible && state.providerUsed === provider.id) &&
                                    <div className={styles.textCTA} data-disabled={state.isReloading} onClick={onReload}>
                                        {t('reload')}
                                    </div>
                            }
                        </div>
                    ))
                }
            </div>
            <CopyrightNote />
        </div>
    )
}

export const getServerSideProps: GetServerSideProps<SignInProps> = async ({ req, locale }) => {
    const lang = locale || 'en'
    const session = await getSession({ req })
    const providers = await getProviders()

    if (session?.token) {
        return {
            redirect: {
                destination: `${lang === 'en' ? '' : ('/' + lang)}/dashboard`,
                permanent: false,
            },
        }
    }

    sentryUserClear()

    return {
        props: {
            ...(await serverSideTranslations(lang, ['auth', 'common'])),
            providers: providers || {},
        }
    }
}

export default SignIn
