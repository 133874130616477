const ExternalLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({ children, ...props }) => (
    <a
        { ...props }
        target="_blank"
        rel="noopener noreferrer external nofollow"
    >
        {children}
    </a>
)

export default ExternalLink
