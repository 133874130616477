import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss'
import InputCharCounter from '../input-char-counter'
import classnames from 'classnames';
import { KalkulInc } from '../../types/Kalkul';
import { removeNestedProps, inputDroppedItemsAllowOnlyAccepted } from '../../services/helpers';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
    'data-size'?: KalkulInc.UI.Size
    'data-pristine'?: boolean
    'data-busy'?: boolean
    dataset?: { title: string, id: string }[]
    errors?: string[]
}

const Input: React.FC<InputProps> = props => {
    const refInput = useRef<HTMLInputElement>(null)
    const [inputFilePlaceholderWidth, setInputFilePlaceholderWidth] = useState(0)
    const [inputFileObserver, setInputFileObserver] = useState<ResizeObserver>()
    const [fileDate, setFileDate] = useState(Date.now())

    useEffect(() => {
        const input = refInput.current

        if (!input) {
            return
        }

        input.setCustomValidity((props.errors && props.errors.length > 0) ? '!' : '')
    }, [props.errors])

    useEffect(() => {
        const input = refInput.current

        if (input && props.type === 'file') {
            setInputFilePlaceholderWidth((input.offsetWidth || 2) - 2)

            inputFileObserver?.disconnect()

            const observer = new ResizeObserver(_entries => {
                const width = (input.offsetWidth || 2) - 2

                if (inputFilePlaceholderWidth !== width) {
                    setInputFilePlaceholderWidth(width)
                }
            })

            setInputFileObserver(observer)

            observer.observe(input)
        }

        return () => {
            if (props.type === 'file') {
                inputFileObserver?.disconnect()
            }
        }
    }, [refInput.current])

    useEffect(() => {
        if (refInput.current && props.type === 'file' && props['data-pristine']) {
            refInput.current.value === null
            setFileDate(Date.now())
        }
    }, [props['data-pristine']])

    let classNames = {
        [styles.wrapper]: true,
    }

    if (props.className) {
        classNames = {
            ...classNames,
            [props.className]: true,
        }
    }

    return (
        <>
            <div className={classnames(classNames)} data-size={props['data-size'] || 'm'}>
                <input
                    {...removeNestedProps(props, [['dataset'], ['errors']])}
                    ref={refInput}
                    className={styles.input}
                    key={fileDate}
                    onDrop={inputDroppedItemsAllowOnlyAccepted}
                />
                {
                    (props.list && props.dataset) &&
                        <datalist id={props.list}>
                            {
                                props.dataset.map(item => (
                                    <option value={item.title} key={item.id} data-id={item.id} />
                                ))
                            }
                        </datalist>
                }
                {
                    props['data-busy'] &&
                        <div className={styles.indicatorBusy}></div>
                }
                {
                    props.type === 'file' &&
                        <div style={{ width: `${inputFilePlaceholderWidth}px` }} className={styles.filePlaceholder}>{props.placeholder}</div>
                }
            </div>
            {
                (props.maxLength && !props.disabled) &&
                    <InputCharCounter inputRef={refInput} value={props.value?.toString() || ''} />
            }
        </>
    )
}

export default Input
